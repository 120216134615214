* {
  box-sizing: border-box;
}

html {
  height: 100%;
  font-size: 0.875rem;
}

body {
  font-family: var(--font-family);
  color: var(--text-color);
  background-color: var(--surface-ground);
  margin: 0;
  padding: 0;
  min-height: calc(100vh - 5rem);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.fade-container {
  transition: opacity 300ms;
}

a,
button {
  text-decoration: none;
  color: var(--primary-color);
}

.layout-wrapper {
  height: 100vh;
  display: flex;
  flex-direction: row;
}

.layout-main-container {
  flex-grow: 1;
  transition: all 0.3s;
  margin-left: 300px;
}

.layout-main-container.layout-icon-sidebar-active {
  margin-left: 100px;
}

.layout-main {
  height: calc(100vh - 4rem);
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  // padding: 2rem;
}

.button-white {
  background-color: #ffffff;
  color: var(--text-color);
  border-color: var(--border-color);
}

.button-white:hover {
  background: var(--surface-300) !important;
  color: var(--text-color) !important;
  border-color: var(--border-color) !important;
}

.hover-opacity-70:hover {
  opacity: 70%;
  transition: opacity 300ms;
}

.extended-filter-remove-button {
  background-color: #ffffff;
  color: var(--text-color);
  border-color: var(--border-color);
  position: absolute;
  right: 0.5rem;
  z-index: 1;
  padding: 0.5rem;
  top: 0.75rem;
  font-size: 0.875rem;
}

.extended-filter-remove-button:hover {
  background: var(--surface-300) !important;
  color: var(--text-color) !important;
  border-color: var(--border-color) !important;
}

.paginator-button {
  background-color: #ffffff;
  color: var(--text-color);
  border-color: var(--border-color);
  padding: 0;
}

.paginator-button.prev {
  border-bottom-right-radius: 0 !important;
  border-top-right-radius: 0 !important;
  border-bottom-left-radius: 0.5rem !important;
  border-top-left-radius: 0.5rem !important;
}

.paginator-button.next {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
  border-bottom-right-radius: 0.5rem !important;
  border-top-right-radius: 0.5rem !important;
}

.paginator-button.index {
  border-radius: 0 !important;
}

.paginator-button.index:hover span {
  color: #ffffff !important;
}

.paginator-button.index:focus,
.paginator-button.next:focus,
.paginator-button.prev:focus {
  box-shadow: none !important;
}

.menu-dropdown {
  background: #324ee1;
  border-radius: 1rem;
  border: none;
}

.menu-dropdown .p-inputtext {
  color: var(--primary-color-text);
}

.menu-dropdown .p-dropdown-trigger {
  color: var(--primary-color-text);
  margin-right: 1rem;
}

.line-height-xl {
  line-height: 1.25rem;
}

[hidden] {
  display: none !important;
}
.optional-text {
  color: #767676;
  font-weight: 400;
  margin-left: 2px;
}
.language-tooltip .p-tooltip-text {
  background-color: #2d276d;
}

.language-dropdown {
  position: absolute;
  top: 3rem;
  right: 10rem;
}

.active-status {
  padding: 0.5rem 2.5rem;
  background: #95dfb2;
  border-radius: 5px;
  color: var(--primary-color-text);
}

.passive-status {
  padding: 0.5rem 2.5rem;
  background: #ff8870;
  border-radius: 5px;
  color: var(--primary-color-text);
}

.custom-column-between {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

//import here other spesific written scss files
@import "applicant.scss";
@import "steps.scss";
@import "header.scss";
@import "flags.scss";
@import "mixin.scss";
@import "login.scss";
@import "menu.scss";
@import "topbar.scss";
@import "landing.scss";
@import "responsive.scss";
@import "status.scss";
