.main {
  display: flex;
  flex-direction: column;
  gap: 10rem;
}

.hero-section {
  display: flex;
  position: relative;
  height: 977px;

  &-content {
    position: absolute;
    z-index: 9;
    .content-box {
      margin-top: 18rem;
      margin-left: 6.8rem;
    }
  }

  .bg-image {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url("../../images/landing-page/landing-hero1.webp");
    mix-blend-mode: darken;
    animation: slide 10s infinite;
  }

  .bg-overlay {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.37);
  }

  @keyframes slide {
    0% {
      background-image: url("../../images/landing-page/landing-hero1.webp");
    }
    33% {
      background-image: url("../../images/landing-page/landing-hero2.webp");
    }
    67% {
      background-image: url("../../images/landing-page/landing-hero3.webp");
    }
  }

  .wave {
    display: flex;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 1;

    img {
      width: 100%;
      object-fit: fill;
    }
  }
}

.contact-us-container {
  position: fixed;
  top: 50%;
  right: 0;
  transform: translate(0, -50%);
  z-index: 1001;
  height: 472px;
  display: flex;
  align-items: center;
}

.contact-us-container-passive {
  right: 0px;
  transition: right 0.5s ease;
}

.contact-us-container-active {
  right: 0;
  transition: right 0.5s ease;
}

.contact-us-form {
  background-color: #ffffff;
  width: 27rem;
  border-top-left-radius: 1rem;
  border-bottom-left-radius: 1rem;
  border: 1px solid #ffffff;
}

.contact-us-span-active {
  color: #4e90cd !important;
  background-color: #ffffff !important;
}

.contact-us-span-passive {
  color: #ffffff;
  background-color: #4e90cd;
  transform-origin: center center;
  writing-mode: vertical-rl;
  font-size: 20px;
  transform: rotate(-180deg);
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
  cursor: pointer;
}

.contact-us-hr {
  border: 1px solid #dadada;
  color: #dadada;
  background-color: #dadada;
}

.contact-us-button {
  background-color: #4e90cd !important;
}

.make-application-button {
  background: linear-gradient(180deg, #4e90cd 0%, #0085ff 100%);
  border: 0px !important;
}

.items-container {
  padding-bottom: 6rem;
  padding-left: 12rem;
  padding-right: 12rem;
}

.object-fit-cover {
  object-fit: cover;
}

.feature-section {
  background: rgba(194, 255, 157, 0.15);

  &-title {
    font-weight: 600;
    font-size: 2.5rem;
    line-height: 49px;
    margin-top: 6.375rem;
    text-align: center;
  }
  .content-box {
    column-count: 3;
  }
}

.become-customer-section {
  background-image: url("../../images/landing-page/become-customer-bg.webp");
  background-size: cover;
  display: flex;
  padding: 5rem;

  &-button {
    padding: 1rem 5rem;
  }
}
.footer {
  display: flex;
  position: relative;
  height: 764px;
  &-content {
    position: absolute;
    z-index: 9;
    .content-box {
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin-top: 26rem;

      .social-icons {
        margin-top: 0.5rem;
        .social-icon {
          margin-right: 1.5rem;
          cursor: pointer;
        }
      }
    }
  }
  .wave {
    display: flex;
    position: absolute;
    bottom: 0;
    width: 100%;
    z-index: 2;
    top: 0;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      background: rgba(0, 0, 0, 0.34);
      mix-blend-mode: darken;
    }
  }

  &-bg {
    width: 100%;
    height: 100%;
    object-fit: cover;
    background: rgba(0, 0, 0, 0.34);
    mix-blend-mode: darken;
  }
}

.social-icons-container {
  background-color: var(--soft-green);
  line-height: 0px;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
}

.tab-container {
  max-width: 1275px;
}

div.landing-tabview ~ a {
  padding: 0;
}

.tab-section {
  .p-tabview
    .p-tabview-nav
    li:not(.p-highlight):not(.p-disabled):hover
    .p-tabview-nav-link {
    border-color: #fbf0f0;
    color: #fbf0f0;
    background: inherit;
  }
  .p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
    box-shadow: none;
  }
  .p-tabview .p-tabview-panels {
    background: inherit;
  }
  .p-tabview .p-tabview-nav {
    background: inherit;
  }
  .p-tabview-nav-content .p-tabview-nav {
    justify-content: center;
    border: none;

    .p-tabview-nav-link {
      border-width: 0 0 10px 0;
      border-color: #fbf0f0;
      padding: 1.25rem 3rem;
      background: inherit;
    }
  }

  .p-tabview-title {
    padding: 1rem;
    border-radius: 1rem;
    color: var(--text-color);
    font-weight: 500;
    font-size: 1.75rem;
  }

  .p-tabview-nav-content .p-tabview-nav .p-highlight .p-tabview-nav-link {
    border-color: var(--soft-green);
    border-radius: 0.5rem;
    background: inherit;

    .p-tabview-title {
      background-color: rgba(165, 207, 97, 0.15);
    }
  }

  .p-tabview-panels .p-tabview-panel .tab-content {
    display: flex;
    flex-direction: column;
    text-align: left;
    justify-content: center;
    // width: 33%;

    .tab-title {
      font-weight: 500;
      font-size: 1.75rem;
      line-height: 25px;
    }
    .tab-description {
      font-weight: 400;
      font-size: 1.375rem;
      line-height: 25px;
    }
  }
}

@media screen and (max-width: 1920px) {
  .hero-section {
    .wave {
      img {
        object-fit: cover;
      }
    }
  }
}

@media screen and (max-width: 1119px) {
  .tab-section {
    .p-tabview-nav-content .p-tabview-nav {
      .p-tabview-nav-link {
        padding: 0;
      }
    }
    .p-tabview-title {
      padding: 1rem 0.5rem;
      font-size: 1.5rem;
    }
  }
}

@media screen and (max-width: 767px) {
  .items-container {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .become-customer-section {
    height: inherit;
  }

  .tab-section {
    .p-tabview-nav-content .p-tabview-nav {
      .p-tabview-nav-link {
        padding: 0;
      }
    }
    .p-tabview-title {
      padding: 1rem 0.5rem;
      font-size: 1rem;
    }
  }
}

@media screen and (max-width: 450px) {
  .tab-section {
    .p-tabview-nav-content .p-tabview-nav {
      justify-content: center;
      .p-tabview-nav-link {
        padding: 0;
      }
    }
    .p-tabview-title {
      padding: 1rem 0.5rem;
      font-size: 0.75rem;
    }
  }

  .contact-us-container {
    max-width: 350px;
  }

  .contact-us-form {
    width: 19rem;
  }

  .hero-section {
    display: flex;
    position: relative;
    height: 977px;

    &-content {
      position: absolute;
      z-index: 9;
      .content-box {
        margin-top: 13rem;
        margin-left: 3.8rem;
      }
    }
  }
}
