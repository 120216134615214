.application-vertical-steps {
  .p-steps {
    overflow: auto;
    ul {
      margin-top: 2.5rem;
      flex-direction: column;
      gap: 1rem;

      li {
        padding-top: 2rem;
        padding-bottom: 2rem;
        border-radius: 1rem;
        padding-left: 1rem;
        @media screen and (max-width: 768px) {
          padding-left: 0;
        }
      }
    }

    .p-steps-item {
      justify-content: left;
      @media screen and (max-width: 768px) {
        justify-content: center;
      }
      &:before {
        border-top: none;
      }
      .p-steps-current {
        background: rgba(230, 230, 230, 0.43);
      }
      .p-menuitem-link {
        background-color: transparent;

        &:not(.p-disabled) {
          &:focus {
            box-shadow: none;
          }
        }
        .p-steps-subtitle {
          color: #767676;
        }
        .p-steps-number {
          background: #dadada;
          color: white;
        }
      }
      &.p-highlight {
        background: rgba(230, 230, 230, 0.43);
        .p-steps-title {
          color: var(--soft-green);
        }
        .p-steps-subtitle {
          color: var(--text-color);
        }
        .p-steps-number {
          background-color: var(--soft-green);
        }
      }
    }
  }
}

.new-application-vertical-steps {
  .p-steps .p-steps-item .p-menuitem-link .p-steps-title {
    margin-top: 0 !important;
  }
  .p-steps .p-steps-item.p-highlight{
    border-bottom: 4px solid var(--soft-green);
    @media screen and (max-width: 1440px) {
      border-bottom: 4px solid var(--soft-green);
    }
    @media screen and (max-width: 768px) {
      
      border-bottom: 3px solid var(--soft-green);
    }
  }

  
  .p-steps {
    overflow: auto;
    ul {
      flex-direction: row;
      border-bottom: 5px solid #dadada;
      gap: 9rem;
      padding-bottom: 3px;
      position: relative;
      bottom: 6px;
      @media screen and (max-width: 1440px) {
        gap: 1rem;
        border-bottom: 4px solid #dadada;
        
      }
      @media screen and (max-width: 768px) {
        gap: 0.5rem;
        border-bottom: 3px solid #dadada;
      }
      li {
        padding-top: 2rem;
        padding-bottom: 1rem;
        padding-left: 1rem;
        position: relative;
        bottom: -7px;
        @media screen and (max-width: 768px) {
          padding-left: 0;
          gap: 0;
          padding-bottom: 0.5rem;
          bottom: -5px;
        }
      }
    }

    .p-steps-item {
      justify-content: left;
      @media screen and (max-width: 768px) {
        justify-content: center;
      }
      &:before {
        border-top: none;
      }
      .p-steps-current {
        background: rgba(230, 230, 230, 0.43);
      }
      .p-menuitem-link {
        background-color: transparent;

        &:not(.p-disabled) {
          &:focus {
            box-shadow: none;
          }
        }
        .p-steps-number {
          background: #dadada;
          color: white;
        }
      }
      &.p-highlight {
        .p-steps-title {
          color: var(--soft-green);
        }
        .p-steps-number {
          background-color: var(--soft-green);
        }
      }
    }
  }
}
