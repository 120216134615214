.layout-sidebar-steps {
  position: fixed;
  height: 100vh;
  z-index: 98;
  // padding-top: 64px;
  padding: 64px 1rem 0px 1rem;
  -webkit-user-select: none;
  user-select: none;
  // left: 0;
  transition: all 0.3s;
  background-color: #ffffff;
  // padding: 0 1rem;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.05),
    0px 1px 4px rgba(0, 0, 0, 0.08);
}

.application-main {
  padding-bottom: 64px;
  position: absolute;
  right: 0px;
  top: 60px;
}

.application-main-bg {
  background: rgba(230, 230, 230, 0.43);
  min-height: calc(100vh - 64px);
  height: 100%;
  padding-bottom: 14px;
}
.applicant-card {
  border: 1px solid #dadada;
  border-radius: 15px;
}

.max-width-multiselect {
  max-width: 333px;
}

.dropdown-height {
  height: 44.8px;
}

.form-right-padding {
  padding-right: 10rem;
}

.form-right-padding-2xl {
  padding-right: 20rem;
}

.verify-text-color {
  color: #abafb1;
}

.verify-title-color {
  color: #202e2c;
}

.primary-color {
  color: #4e90cd;
}

.optional-color {
  color: #767676;
}

.standart-name-cnt {
  border-radius: 4px;
  padding: 5px;
  font-weight: 700 !important;
  color: #4e90cd;
  font-size: 1.5rem;
}

.primary-bg-color {
  background-color: #4e90cd;
}

.application-container {
  height: calc(100vh - 64px);
}

.upload-file-container {
  .p-fileupload-choose {
    background-color: #f3fbff !important;
    color: #4e90cd !important;
    width: 100%;
    border: #4e90cd dashed 1px !important;
    border-radius: 1rem;
  }
  .p-button {
    text-align: start;
  }
}

.uploader-file-container {
  background-color: #f3fbff !important;
  color: #4e90cd !important;
  width: 100%;
  border: #4e90cd dashed 1px !important;
  border-radius: 0.5rem;
}

.my-file-container {
  .p-fileupload-advanced {
    min-height: 150px;
    background-color: #f3fbff !important;
    color: #4e90cd !important;
    width: 100%;
    border: #4e90cd dashed 1px !important;
    border-radius: 1rem;
    padding: 1rem !important;
  }

  .p-fileupload-content {
    border: 0px !important;
    background: none !important;
  }

  .p-progressbar {
    width: 0 !important;
  }

  .p-fileupload-buttonbar {
    border: 0px !important;
    background: none !important;
  }
}

.cic-file-container {
  .p-fileupload-advanced {
    display: flex;
    flex-direction: column-reverse;
    min-height: 150px;
    background-color: #f3fbff !important;
    color: #4e90cd !important;
    width: 100%;
    border: #4e90cd dashed 1px !important;
    border-radius: 1rem;
    padding: 1rem !important;
  }

  .p-fileupload-content {
    border: 0px !important;
    background: none !important;
    padding: 0rem;
  }

  .p-progressbar {
    width: 0 !important;
  }

  .p-fileupload-buttonbar {
    border: 0px !important;
    background: none !important;
    padding: 1rem;
  }
}

.custom-file-upload {
  display: flex;
  flex-direction: column;
  background-color: #f3fbff !important;
  color: #4e90cd !important;
  width: 100%;
  border: #4e90cd dashed 1px !important;
  border-radius: 1rem;
  padding: 1rem !important;
}
.custom-file-upload .p-fileupload .p-fileupload-buttonbar {
  border: none;
  background-color: inherit;
}

.custom-file-upload .p-fileupload .p-fileupload-content {
  display: none;
}

.file-upload-only .p-fileupload .p-fileupload-buttonbar {
  padding: 0;
}

.my-phone-code .p-dropdown-label {
  padding-top: 0;
  padding-bottom: 0;
  display: flex;
  align-items: center;
}

.additional-components-container {
  .p-accordion-header-link {
    border-top-left-radius: 1rem !important;
    border-top-right-radius: 1em !important;
  }

  .p-accordion-content {
    border-bottom-right-radius: 1rem !important;
    border-bottom-left-radius: 1rem !important;
  }

  .p-accordion
    .p-accordion-tab:last-child
    .p-accordion-header:not(.p-highlight)
    .p-accordion-header-link {
    border-bottom-right-radius: 1rem !important;
    border-bottom-left-radius: 1rem !important;
  }
}

.dialog-width {
  width: 60vw;
}

.small-dialog-width {
  width: 30vw;
}

.hr-color {
  border: 1px solid #dadada;
}

.step4-container {
  border-top-right-radius: 1rem !important;
  border-top-left-radius: 1rem !important;
  border-bottom-right-radius: 1rem !important;
  border-bottom-left-radius: 1rem !important;
  border: 1px solid #e5e7eb !important;
  background-color: #ffffff !important;
}

.step4-accordion-container {
  .p-accordion-header-link {
    border-top-right-radius: 1rem !important;
    border-top-left-radius: 1rem !important;
    border-bottom-right-radius: 1rem !important;
    border-bottom-left-radius: 1rem !important;
    border: 1px solid #e5e7eb !important;
    background-color: #ffffff !important;
  }

  .p-accordion-content {
    margin-top: 1rem !important;
    border-top-right-radius: 1rem !important;
    border-top-left-radius: 1rem !important;
    border-bottom-right-radius: 1rem !important;
    border-bottom-left-radius: 1rem !important;
    border: 0px solid #e5e7eb !important;
    background-color: #ffffff !important;
    padding: 0 !important;
  }
}

.download-example-file {
  color: #ffffff;
  background-color: #4e90cd;
  border: 1px solid #4e90cd;
  border-radius: 12px;
  font-weight: 400;
  cursor: pointer;
}

.text-link {
  cursor: pointer;
}

.under-review-bg-color {
  background-color: #ffd875;
  width: fit-content;
}
.offer-stage-bg-color {
  background-color: #a895b5;
  width: fit-content;
}
.offer-sent-bg-color {
  background-color: #7d82f2;
  width: fit-content;
}
.offer-approved-bg-color {
  background-color: #a5cf61;
  width: fit-content;
}
.soft-green-bg {
  background-color: #a5cf61;
}
.soft-green {
  color: #a5cf61;
}
.soft-green2 {
  background-color: #a5cf6133;
}

.text-link:hover {
  text-decoration: underline;
}

.border-left-right {
  border-left: 1px solid #dadada;
  border-right: 1px solid #dadada;
}

.custom-margin-top {
  margin-top: 14px;
}

.pdf-download-link {
  text-decoration: none;
  padding: 7px 14px;
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
  border-radius: 5px;
  font-weight: 500;
  font-size: 14px;
  width: max-content;
}

.file-header-template {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  gap: 0.25rem;
}

@media (max-width: 1230px) and (min-width: 991px) {
  .file-header-template {
    flex-direction: column;
  }
}

@media (max-width: 1400px) {
  .form-right-padding {
    padding-right: 0;
  }

  .form-right-padding-2xl {
    padding-right: 2rem;
  }

  .dialog-width {
    width: 80vw;
  }

  .small-dialog-width {
    width: 60vw;
  }
}

@media screen and (max-width: 768px) {
  .dialog-width {
    width: 100vw;
  }

  .small-dialog-width {
    width: 90vw;
  }

  .border-left-right {
    border-left: 0px solid #dadada;
    border-right: 0px solid #dadada;
  }
}
