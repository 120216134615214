.layout-sidebar {
  position: fixed;
  width: 300px;
  height: 100vh;
  z-index: 999;
  user-select: none;
  left: 0;
  transition: all 0.3s;
  background-color: #ffffff;
  padding: 0.5rem 1rem;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.05),
    0px 1px 4px rgba(0, 0, 0, 0.08);
    overflow-y: auto;
    overflow-x: hidden;
}

.layout-menu {
  margin: 0;
  padding: 0;
  list-style-type: none;
  user-select: none;

  .layout-root-menuitem {
    > .layout-menuitem-root-text {
      font-size: 0.857rem;
      text-transform: uppercase;
      font-weight: 700;
      color: var(--primary-color-text);
      margin: 0.75rem 0;
    }

    > a {
      display: none;
    }
  }

  li.active-menuitem {
    > a {
      .layout-submenu-toggler {
        transform: rotate(-180deg);
      }
      background: #dff4ff;
      border-radius: 16px;
      color: var(--primary-color);
      font-weight: 500;
    }

    > ul {
      max-height: auto;
    }
  }

  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;

    a {
      display: flex;
      align-items: center;
      position: relative;
      outline: 0 none;
      color: #8f9695;
      cursor: pointer;
      padding: 0.75rem 1rem;
      border-radius: 16px;
      transition: background-color 0.2s, box-shadow 0.2s;

      .layout-menuitem-icon {
        margin-right: 0.5rem;
      }


      object[type="image/svg+xml"] svg path{
        fill: red;
      }

      .layout-submenu-toggler {
        font-size: 75%;
        margin-left: auto;
        transition: transform 0.2s;
      }
      .layout-submenu-toggler.icon-menu-item {
        margin-left: unset;
      }

      &.rotated-icon {
        .layout-menuitem-icon {
          transform: rotate(90deg);
        }
      }

      &.active-route {
        font-weight: 700;
        color: var(--primary-color);
      }

      &:hover {
        background-color: #dff4ff;
        color: var(--primary-color);
      }
    }

    ul {
      overflow: hidden;
      max-height: 0;
      border-radius: 16px;

      // li {
      //   a {
      //     margin-left: 1rem;
      //   }

      //   li {
      //     a {
      //       margin-left: 2rem;
      //     }

      //     li {
      //       a {
      //         margin-left: 2.5rem;
      //       }

      //       li {
      //         a {
      //           margin-left: 3rem;
      //         }

      //         li {
      //           a {
      //             margin-left: 3.5rem;
      //           }

      //           li {
      //             a {
      //               margin-left: 4rem;
      //             }
      //           }
      //         }
      //       }
      //     }
      //   }
      // }
    }
  }
}

.layout-submenu-enter-active {
  max-height: 1000px !important;
  transition: max-height 1s ease-in-out;
  background: #dff4ff;
  border-radius: 16px;
}

.layout-submenu-enter-done {
  max-height: 1000px !important;
  background: #dff4ff;
  border-radius: 16px;
}

.layout-submenu-exit {
  max-height: 1000px !important;
}

.layout-submenu-exit-active {
  overflow: hidden;
  max-height: 0 !important;
  transition: max-height 0.45s cubic-bezier(0, 1, 0, 1);
}

.menu-toggle {
  background-color: #ffffff;
  border-radius: 0 10px 10px 0;
  position: absolute;
  top: 1rem;
  right: -1.25rem;
  cursor: pointer;
  padding: 0.25rem 0.25rem 0.25rem 0;
  box-shadow: rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px;
}

.menu-toggle.icon-menu-active i {
  transform: rotate(-180deg);
  transition: transform 0.3s;
}

.layout-sidebar.layout-icon-sidebar-active {
  width: 100px;

  .layout-menu ul a {
    justify-content: center;
    transition: justify-content 0.2s;
  }
}

span.icon-menu {
  display: none;
}

.layout-topbar-logo {
  display: flex;
  justify-content: center;
  justify-items: center;
  margin: 2rem 0;
}

.layout-topbar-logo img {
  aspect-ratio: 148/110;
}

.logo-divider {
  width: calc(100% - 2rem);
  height: 8px;
  margin: 0 1rem;
  background: rgba(217, 217, 217, 0.24);
  border-radius: 30px;
}
