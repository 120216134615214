/* Suggested location to add your overrides so that migration would be easy */
.p-password input {
  width: 100% !important;
  padding: 0.75rem !important;
}

.p-datatable-header {
  padding-left: 0 !important;
  padding-right: 0 !important;
  padding-top: 0 !important;
  padding-bottom: 1rem !important;
}

.p-datatable.p-datatable-gridlines .p-datatable-header {
  border: none !important;
}

.p-datatable .p-datatable-thead > tr > th {
  background-color: #f1f1f1 !important;
  color: var(--text-color);
  font-weight: 600;
  border-color: var(--border-color);
}

.p-datatable-wrapper {
  border-radius: 0.5rem;
}
.custom-datatable .p-datatable-wrapper {
  height: 72vh;
  margin-bottom: 2rem;
  position: absolute;
  width: 100%;
}

.custom-datatable .p-datatable-footer {
  position: absolute;
  top: 80vh;
  width: 100%;
}

.p-datatable.p-datatable-sm .p-datatable-thead > tr > th {
  padding: 1rem 0.75rem !important;
}

.p-datatable .p-datatable-tbody > tr > td {
  border-color: var(--border-color);
}

.p-datatable-thead tr:first-child th:first-child {
  border-top-left-radius: 0.5rem;
}

.p-datatable-thead tr:first-child th:last-child {
  border-top-right-radius: 0.5rem;
}

.p-datatable-tbody tr:last-child td:first-child {
  border-bottom-left-radius: 0.5rem;
}

.p-datatable-tbody tr:last-child td:last-child {
  border-bottom-right-radius: 0.5rem;
}

.h-full .p-datatable {
  height: 100% !important;
  display: flex !important;
  flex-direction: column !important;
}

.h-full .p-datatable .p-datatable-wrapper {
  flex-grow: 1;
}

.p-datatable.p-datatable-sm .p-datatable-footer {
  padding: 0 !important;
  background: transparent !important;
}

.p-datatable.p-datatable-gridlines .p-datatable-footer {
  border: none !important;
}

.p-datatable-footer .p-paginator {
  background: transparent !important;
  padding: 0 !important;
}

// .p-dialog-content {
//   border-radius: 1rem;
// }

.no-padding.p-dialog .p-dialog-content {
  padding: 0 !important;
}

.no-padding.p-dialog .p-dialog-header {
  padding: 0 !important;
  border-bottom: 1px solid #dee8f5;
  border-top-right-radius: 1rem !important;
  border-top-left-radius: 1rem !important;
}

.no-padding.p-dialog .p-dialog-header .p-dialog-header-icons {
  display: none !important;
}

.no-padding.p-dialog .p-dialog-footer {
  padding: 0 !important;
  border-top: 1px solid #dee8f5;
  border-bottom-right-radius: 1rem !important;
  border-bottom-left-radius: 1rem !important;
}

.field .p-dropdown .p-inputtext {
  padding: 0 !important;
  box-shadow: none !important;
}

.white-upload-button.p-fileupload-basic .p-button {
  color: var(--primary-color) !important;
  border-color: var(--border-color) !important;
  background-color: #ffffff !important;
  width: 100%;
  padding: 1.125rem;
  text-align: left !important;
}

.profile-photo-file-upload .white-upload-button.p-fileupload-basic .p-button {
  width: 134px;
  height: 134px;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: inherit !important;
}

.p-button-label {
  text-wrap: nowrap;
}

.profile-photo-file-upload .p-button-label {
  flex: unset;
  color: white;
  opacity: 0.7;
}
.profile-photo-file-upload .p-button {
  background: inherit;
}

.profile-photo-file-upload .p-button .p-button-icon-left {
  color: white;
  opacity: 0.7;
  margin-right: 0;
  font-size: 20px;
}

.white-upload-button .pi.pi-plus {
  display: none;
}

.p-datatable .p-datatable-loading-overlay {
  background: rgba(255, 255, 255, 0.6) !important;
}
.p-datatable .p-datatable-loading-icon {
  font-size: 4rem !important;
}

// .p-confirm-dialog.custom-confirmation {
//   width: 30%;
// }

.p-confirm-dialog.custom-confirmation .p-dialog-content {
  padding: 0.5rem 1.5rem 3rem 1.5rem !important;
  justify-content: center;
}

.p-confirm-dialog.custom-confirmation
  .p-dialog-content
  .p-confirm-dialog-message {
  margin: 0;
}

.p-confirm-dialog.custom-confirmation .p-dialog-header {
  border-top-right-radius: 1rem !important;
  border-top-left-radius: 1rem !important;
}

.p-confirm-dialog.custom-confirmation .p-dialog-footer {
  border-top: 1px solid var(--border-color);
  border-bottom-right-radius: 1rem !important;
  border-bottom-left-radius: 1rem !important;
  padding-top: 1.5rem;
  display: flex;
  justify-content: center;
  gap: 2rem;
}

.p-confirm-dialog.custom-confirmation .p-dialog-header .p-dialog-header-icon {
  color: var(--text-color);
}

.p-confirm-dialog.custom-confirmation .p-dialog-footer .p-confirm-dialog-reject,
.p-confirm-dialog.custom-confirmation
  .p-dialog-footer
  .p-confirm-dialog-accept {
  padding-left: 3rem;
  padding-right: 3rem;
}

.p-toast-message {
  background: #ffffff !important;
}

.p-toast-message .p-toast-detail {
  font-size: 0.875rem;
}

.p-toast-message.custom-toast {
  background: #ffffff !important;
  border-width: 0 0 5px 0 !important;
  color: var(--text-color) !important;
}

.p-toast-message.custom-toast .p-toast-summary {
  font-size: 1.125rem !important;
  font-weight: 600 !important;
}

.p-toast-message.p-toast-message-success {
  border: 5px solid #4aae50 !important;
  border-width: 0 0 5px 0 !important;
}

.p-toast-message.p-toast-message-success .p-toast-summary {
  color: #4aae50 !important;
}

.p-toast-message.p-toast-message-error {
  border: 5px solid #ff5652 !important;
  border-width: 0 0 5px 0 !important;
}

.p-toast-message.p-toast-message-error .p-toast-summary {
  color: #ff5652 !important;
}

.p-toast-message.p-toast-message-info {
  border: 5px solid #4f5aba !important;
  border-width: 0 0 5px 0 !important;
}

.p-toast-message.p-toast-message-info .p-toast-summary {
  color: #4f5aba !important;
}

.p-toast-message.p-toast-message-warn {
  border: 5px solid #d97706 !important;
  border-width: 0 0 5px 0 !important;
}

.p-toast-message.p-toast-message-warn .p-toast-summary {
  color: #d97706 !important;
}

// .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
//   transform: translateX(35%);
// }

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
  background-color: #0cbc8b !important;
}

.p-inputswitch.p-focus .p-inputswitch-slider {
  box-shadow: none !important;
}

.extended-filter-overlay.p-overlaypanel .p-overlaypanel-content {
  padding: 0.5rem;
  position: relative;
}

.extended-filter-tabview.p-tabview .p-tabview-nav-container {
  margin-right: 100px;
}

.extended-filter-tabview.p-tabview .p-tabview-nav li .p-tabview-nav-link {
  padding: 0.75rem 1rem;
}

.extended-filter-tabview.p-tabview .p-tabview-panels {
  padding: 0rem;
}

.extended-filter-listbox.p-listbox {
  border: none;
}

.extended-filter-listbox.p-listbox .p-listbox-header {
  background: transparent;
  padding: 0.5rem 0rem;
}

.p-radiobutton {
  position: relative !important;
}

.document-div {
  background-color: #dee8f5 !important;
}

.document-calendar .p-inputtext.p-component {
  padding: 1rem !important;
}

.p-hidden-accessible {
  position: unset !important;
  height: unset !important;
}
